.container-fluid{
    padding-left: 0;
    padding-right: 0;
  }

.blockquote-footer {
    margin-left: 5%;
    font-style: normal;
}

.home-left-bloc-one{
    margin-top: 35%;
    margin-bottom: 10%;
    background-color:rgba(255,255,255,0.8);
}

.home-left-bloc-one-bis{
    margin-top: 15%;
    margin-bottom: 10%;
    padding: auto;
    color: #0D788F;
    text-align: center;
    background-color:rgba(255,255,255,0.8);
}

.home-right-bloc-one{
    margin-top: 35%;
    margin-bottom: 15%;
    background-color:rgba(255,255,255,0.8);
}

.btn-outline-dark{
    margin-top: 4%;
    margin-bottom: 0;
}

.btn-outline-dark:hover{
    background-color: #0D788F;
    color: light;
    border-color: #0D788F;
}