.monprojet{
    padding-top: 120px;
}

.list-projects > li {
    list-style-type: none; 
}

.project-list{
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: center;
    background-color:#fafafa;
}

.project-desc{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    background-color:#fafafa;
}

.project-all-doc{
    padding:0;
    background-color:white;
}

.doc-link{
    color: black;
}

.doc-link:hover, 
.doc-link:focus {
    color: black;
}

input[type="file"] {
    z-index: -1;
    top: 10px;
    left: 8px;
    font-size: 17px;
    color: #b8b8b8;
}

.buttaddoc{
    margin-top: 10px;
    margin-bottom: 10px;
}

.buttshowdoc{
    width: 100%;
    color : black;
    
}

.project-title-left{
    text-align: left;
}

.project-title-right{
    text-align: right;
}

.title_project{
    margin-bottom: 10px;
}
