#Conception{
    margin-top: 10%;
}

#Conception h2{
    margin-bottom: 5%;
}

#Conception .number{
    display:inline;
    font-size: 175%;
    font-weight:bold;
}

#Conception .title-conception{
    display:inline;
    font-size: 150%;
    font-weight:bold;
}

.bloc-conception{
    margin-top: 3%;
    margin-bottom: 3%;
}

.conception-block{
    margin-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.conception-block img{
    width: 200px;
    margin-top: 1%;
    padding: 0;
}

.list-block li {
    list-style-type: none; 
}

.end-conception{
    margin-top:8%;
    margin-bottom:8%;
    font-size: 200%;
    text-align: center;
    font-weight:bold;
}

.logo-signature img{
    width: 150px;
    margin: 0;
    padding: 0;
}

.text-signature{
    display: flex;
    align-items: center;
    padding-top : 5%;
    padding-bottom : 5%;
    font-size: 140%;
}