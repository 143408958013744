.blockquote-footer {
    margin-left: 20px;
    font-style: italic;
}

.navbar {
	margin:0 !important;
	padding: 0px !important;
    /*justify-content: end !important;*/
    color: blue;
}

.navbar .navbar-nav .nav-link {
    color: #000000;
}

.navbar-nav > li{
    /* margin-left:30px;
    margin-right:30px;
    margin:0 !important;
	padding: 0px !important; */
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
}

@media (min-width: 768px) {
    .navbar-nav > li:hover {
        /* color: #0D788F; */
        transform: scale(1.25);
        /* font-size: 150%; */
        /* font-size: 150%; */
    }
}

/*.navbar-nav:hover {
    color: #0D788F; 
    font-size: 150%; 
    transform: scale(1.25); 
}*/

/*.navbar-light .navbar-nav .nav-link:hover {
    color: #0D788F;
}*/

.navbar-nav .nav-item{
    color: #000000;
}
  
.bi-telephone{
    color: #000000;
}

#nav-op
{  
    background-color:white; /*whichever you want*/
    opacity: 0.8; 
    filter:(opacity=80);
    display: block;    
}


.colorText{
    color: #000000;
}

/*.colorText:hover{
    color: #0D788F;
}*/


.colorText:focus,.btn:active {
    outline: none !important;
    box-shadow: none !important;
 }

 .navbar-brand img {
    height: 75px;
  }