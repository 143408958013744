.ModalContact{
    text-align: center;
}

.contact-first{
    padding: 1em;
    font-size: large;
    color: white;
    background-color: #0D788F;
}

.contact-second{
    padding: 1em;
    font-size: large;
    color: #0D788F;
    border-color: #0D788F;
    background-color: white;
    border-style: solid;
    border-width: medium;
}