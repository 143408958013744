.conversation{
    /* display: flex; */
    height: 400px;
    /* overflow-x: hidden; */
    overflow-y: auto;
    pointer-events: all;
    /* align-self: center;
    align-items: center; 
    align-content: center;
    justify-content: center; */
    /* width: 400px; */
    /* background-color: black; */
	/* margin:0 !important;
	padding: 0px !important; */
}

.list-messages > li {
    list-style-type: none; 
    pointer-events: all;
}

.messageContainer{
    /* display: flex; */
    /* text-align: flex-end; */
    /* -moz-border-radius: 15%;
    -webkit-border-radius: 15%; */
    /* border-radius: 15%; */
    margin-bottom: 10px;
    pointer-events: all;
    /* margin-left: 10px; */
    /* padding-left: 5px; */
    /* padding-right: 3%; */
    padding-top: 2%;
    padding-bottom: 2%;
    width: 80%;
    /* width: 200px; */
}


.myMessageContainer {
    flex: 1;
    background-color: #decccc;
    border-radius: 20px;
    margin-left: 20%;
    /* align-self: flex-end;
    align-items: flex-end;
    align-content: flex-end; */
}

.myMessage{
    /* width: 70%; */
    color: black
}

.profilImageContainer {
}

.myPictureContainer {
    /* flex: 1; */
    /* background-color: aqua; */
}

.date {
    flex: 1;
    color: grey;
    /* background-color: yellow; */
    align-self: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    margin-right: 2px;
    /* float: right; */
}

.dateContainer {
    /* flex: 1; */
    /* display: flex; */
    /* background-color: yellowgreen; */
    /* align-self: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: end */
}

.msgDateContainer {
    flex: 1;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    /* background-color: yellowgreen; */
    /* align-self: flex-end; */
    /* align-items: flex-end;
    align-content: flex-end; */
}

.profileMessage {
    /* background-color: aquamarine; */
    /* flex: 1; */
    border-radius: 50px;
    height: 75px;
    width: 75px;
    align-self: center;
    /* align-items: center;
    align-content: center; */
    margin-left: 3px; 
    margin-right: 3px;   
    /* float: right; */
}

.pictureMessage {
    /* background-color: aquamarine; */
    /* flex: 1; */
    border-radius: 5px;
    height: 100px;
    width: 100px;
    /* align-self: center;
    align-items: center;
    align-content: center; */
    margin-left: 8px;
    /* float: right; */
}

.hisMessageContainer {
    /* flex: 1; */
    background-color: #ffffff;
    border-radius: 20px;
    margin-right: 20%;
    margin-left: 10px;
    /* align-self: flex-end;
    align-items: flex-end;
    align-content: flex-end; */
}

.hisMessage{
    color: black
}

.inputMessenger {
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    align-items: center;
    border-color: pink;
}
textarea:focus { 
    outline: none !important;
    border-color: black;
    /* box-shadow: 0 0 10px black; */
}

.inputMessenger:focus {
    color: #ffffff; 
    background-color: #161617; 
    border-color: #494F57;
    outline: none;
}

.inputMessenger:active:focus {
    color: #ffffff; 
    background-color: #161617; 
    border-color: #494F57;
}

.input-group:focus-within label {
    color: blue !important;
  }

.cursor {
    cursor: pointer;
}

.textarea {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;

}

.pictureContainer {
    
}

.noMargin {
    margin: 0;
    padding: 0
}

.breakWord {
    word-break: break-word;
}

.newDay {
    color: black;
    justify-content: center;
    text-align: center;
    background-color: seashell;
    margin-left: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
}

