.fenetreContainer{ 
    border-radius: 20px;

}

.list-test > li {
    list-style-type: none; 
}

.addMargin {
    /* padding: 0px !important; */
    /* background-color: aqua; */
    /* background: rgba(255,0,0,0.5); */
    pointer-events: none;
    background-color: 'transparent';
    bottom: 0;
    position: fixed;
    width: 100%;
    /* flex:1 */
}

.pointerAll {
    pointer-events: all;
}

.rightMargin {
    margin-right: 15px;
}

.navbar-nav-bottom > li{
    /* margin-left:30px;
    margin-right:30px;
    margin:0 !important;
	padding: 0px !important; */
    /* margin-left: 50px;
    margin-right: 50px; */
    margin-bottom: 50px;
    margin-right: 50px;
}

.sortable tr {
    cursor: pointer;
}

.topConversation {
    background-color: #0D788F;
    height: 2px;
    /* width: 400px; */
}

.exitButton {
    color: aliceblue;
}

.messengerContainer {
    background-color:#dcd9d9;
    /* width: 25%; */
    width: 400px;
}

.pictureContainer {
    background-color: #0D788F;
    /* padding-bottom: 2px; */
}

.textConceptArt {
    color: white;
    text-align: center;
    font-size: large;
    padding-bottom: 3px;
    /* margin-bottom: 3px; */
}