.title{
    color: #46579C;
    /* color: white; */
    text-align: center;
    font-size: xx-large;
    /* background-color: #86bcc8; */
    /* background-color: #46579C; */
    /* border-color: #d57d55; */
    border-color: #86bcc8;
    border-width: 2px;
    border-style:solid;
    /* border:1px; */
    font-family: 'Oswald'
}

.fontFamily {
  font-family: 'Oswald'

}

.miniSpace{
    height: 20px;
}

.space{
    height: 35px;
}

.selectContainer {
    width: 200px
  }

.marginHorizontal{
    margin-right: 30px;
    margin-left: 30px;
}

.btn-outline-dark{
    /* margin-top: 20px; */
    margin-bottom: 0px;
    color: black;
    font-family: 'Oswald'
}

.btn-outline-dark:active:focus {
  color: #ffffff; 
  background-color: #161617; 
  border-color: #494F57;
}

.btn-outline-dark:focus,.btn:active {
    outline: none !important;
    box-shadow: none !important;
 }


.btn-outline-dark:hover{
    background-color: #86bcc8;
    color: light;
    border-color: #86bcc8;
}

.btn-outline-dark:active {
  box-shadow: none !important;
  color: #ffffff !important;
  background-color:  #00a5da   !important;
  border-color: #494F57 !important;
}

.choice{
    background-color: #0D788F;
    color: light;
    border-color: #0D788F;
    color: white
}

.nopaddingWithMargin {
    padding: 0 !important;
    /* margin: 0 !important; */
    margin-right: 3px;
    margin-left: 3px;
 }


 .miniMargin{
  /* padding: 45px; */
  margin: 15px;
  font-family: 'Oswald'
 }

.custom-select{
  /* background-color: blue; */
    font-family: 'Oswald'
}

.custom-select:hover{
  background: #86bcc8;
}

.custom-select:hover option{
  background: white;
}

.custom-select.btn-group .dropdown-menu li:hover{
  background-color: rgb(191, 82, 121);
  color: white;
}

.custom-select:not(:focus){
  /* background: blueviolet; */
}


.custom-select select{
  /* background: #0D788F; */
  background: white;
  /* background: blueviolet; */
}

textarea {
  resize: none;
  font-family: 'Oswald'
}

.btn-concepart {
  font-size: large;
  background-color: #0D788F;
  border-color: #0D788F;
  font-family: 'Oswald';
  /* background: #46579C; */
}

.btn-concepart:hover{
  background: #86bcc8;
  border-color: #86bcc8;
}

/* .btn-concepart:focus,.btn:active {
  outline: none !important;
  box-shadow: none !important;
} */

/* .btn-concepart:active {
  box-shadow: none !important;
  color: #ffffff; 
  background-color: yellow; 
  border-color: #494F57;
} */

.btn-concepart:focus {
  box-shadow: none !important;
  color: #ffffff !important;
  background-color: #0D788F;
  border-color: #0D788F;
}
.btn-concepart:active {
  box-shadow: none !important;
  color: #ffffff !important;
  background-color:  #00a5da   !important;
  border-color: #494F57 !important;
}
